import React, { useState, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import { Button } from '../buttons/Button/Button';
import styles from './Modal.module.scss';

interface ModalProps {
  title: string;
  open: boolean;
  onOk?: () => void;
  onCancel: () => void;
  okText?: string;
  cancelText?: string;
  children: React.ReactNode;
  disabled?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  title,
  open,
  onOk,
  onCancel,
  okText = 'OK',
  cancelText = 'Cancel',
  children,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (open) {
      setIsOpen(true);
      timeoutId = setTimeout(() => setAnimationClass(styles.modalOpen), 10);
    } else {
      setAnimationClass('');
      timeoutId = setTimeout(() => setIsOpen(false), 300);
    }
    return () => clearTimeout(timeoutId);
  }, [open]);

  const handleClose = () => {
    setAnimationClass('');
    setTimeout(() => {
      onCancel();
    }, 300);
  };

  if (!isOpen) return null;

  return (
    <div className={`${styles.modalOverlay} ${animationClass}`} onClick={handleClose}>
      <div className={`${styles.modal} ${animationClass}`} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <h2>{title}</h2>
          <button className={styles.closeButton} onClick={handleClose}>
            <IoClose />
          </button>
        </div>
        <div className={styles.modalContent}>{children}</div>
        {onOk && (
          <div className={styles.modalFooter}>
            <Button variant="outline" onClick={handleClose}>
              {cancelText}
            </Button>
            <Button onClick={onOk} disabled={disabled}>
              {okText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;