import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../../../components/inputs/Input/Input';
import { Dropdown } from '../../../../../components/dropdown/Dropdown';
import useCountries from '../../../../../hooks/useCountries';
import { IoIosArrowDown } from 'react-icons/io';
import styles from './SiteDetails.module.scss';

const SiteDetails: React.FC = () => {
  const { control, setValue, watch } = useFormContext();
  const { countries } = useCountries();
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const watchCountry = watch('provider.country');

  useEffect(() => {
    if (countries && watchCountry) {
      const matchingCountry = countries.find(c => c.country === watchCountry);
      if (matchingCountry) {
        setSelectedCountry(matchingCountry.label);
        setValue('provider.currency', matchingCountry.currency);
      }
    }
  }, [watchCountry, countries, setValue]);

  const handleCountrySelect = (label: string) => {
    setSelectedCountry(label);
    const selectedCountryData = countries?.find(c => c.label === label);
    if (selectedCountryData) {
      setValue('provider.country', selectedCountryData.country, { shouldValidate: true });
      setValue('provider.currency', selectedCountryData.currency);
    }
  };

  return (
    <div className={styles.container}>
      <h2>Site Details</h2>
      <div className={styles.content}>
        <Input
          name="provider.label"
          label="Site Label"
          placeholder='Site Label (e.g. GI Alliance Texas)'
          control={control}
          rules={{ required: 'Provider name is required' }}
          required
        />
        <Input
          name="provider.description"
          label="Description"
          placeholder='For additional context (e.g. "GIA site in Texas. Key contact MD Adam Alias")'
          control={control}
          rules={{ required: 'Description is required' }}
          required
        />
        <div className={styles.dropdownWrapper}>
          <label htmlFor="provider.country" className={styles.label}>Country *</label>
          <Dropdown
            menu={{
              items: countries?.map(country => country.label).filter(c => c !== selectedCountry) || [],
              onSelect: handleCountrySelect,
            }}
            size="small"
          >
            <div className={styles.preferenceValue}>
              {selectedCountry || 'Select Country'}
              <span className={styles.iconWrapper}>
                <IoIosArrowDown />
              </span>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default SiteDetails;
