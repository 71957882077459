import React, { useContext, useState, useMemo, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Modal from "../../../../../components/modal/Modal";
import { Form } from "../../../../../components/Form/Form";
import Input from "../../../../../components/inputs/Input/Input";
import { Dropdown } from "../../../../../components/dropdown/Dropdown";
import styles from "./EditPricingDetailsModal.module.scss";
import { LoginContext } from "../../../../../contexts/LoginContext/LoginContext";
import { authFetch } from "../../../../../utils/utils";
import { IoIosArrowDown } from 'react-icons/io';
import toast from "react-hot-toast";
import { Sku } from "../../../../../hooks/useSkus";
import { PricingDetails } from "../PricingTable";

// Form content component to access form context
const FormContent: React.FC<{
  selectedSku: string | null;
  handleSkuSelect: (item: string) => void;
  skuOptions: string[];
  selectedCurrency: string | null;
  handleCurrencySelect: (item: string) => void;
  currencyOptions: string[];
  validateStartDate: (value: string) => true | string;
  validateEndDate: (value: string) => true | string;
  submitError: string | null;
}> = ({
  selectedSku,
  handleSkuSelect,
  skuOptions,
  selectedCurrency,
  handleCurrencySelect,
  currencyOptions,
  validateStartDate,
  validateEndDate,
  submitError,
}) => {
  const { clearErrors } = useFormContext();
  
  const handleSkuSelectWithReset = (item: string) => {
    handleSkuSelect(item);
    clearErrors("started_on");
  };

  return (
    <>
      <div className={styles.inputGroup}>
        <Input 
          name="pricing_id" 
          label="Pricing ID" 
          disabled
          fixedWidth={75}
        />
        <div className={styles.dropdownWrapper}>
          <label htmlFor="sku_id">SKU *</label>
          <Dropdown
            menu={{
              items: skuOptions.filter(sku => sku !== selectedSku),
              onSelect: handleSkuSelectWithReset,
            }}
            size="small"
          >
            <div className={styles.preferenceValue}>
              {selectedSku || 'Select SKU'}
              <span className={styles.iconWrapper}>
                <IoIosArrowDown />
              </span>
            </div>
          </Dropdown>
        </div>
      </div>
      <Input 
        name="description" 
        label="Product Name" 
        placeholder="Product Name (e.g. 'MedAIRE 2 - US (Westminster Medical Clinic)')"
        rules={{ required: 'Product name is required' }} 
        required
      />
      <div className={styles.inputGroup}>
        <Input 
          name="started_on" 
          label="Started On" 
          type="datetime-local"
          rules={{ 
            required: 'Start date is required',
            validate: validateStartDate
          }} 
          required
        />
        <Input 
          name="ended_on" 
          label="Ended On" 
          type="datetime-local"
          rules={{ 
            required: 'End date is required',
            validate: validateEndDate
          }} 
        />
      </div>
      <div className={styles.inputGroup}>
        <div className={styles.dropdownWrapper}>
          <label htmlFor="currency">Currency *</label>
          <Dropdown
            menu={{
              items: currencyOptions.filter(c => c !== selectedCurrency),
              onSelect: handleCurrencySelect,
            }}
            size="small"
          >
            <div className={styles.preferenceValue}>
              {selectedCurrency || 'Select Currency'}
              <span className={styles.iconWrapper}>
                <IoIosArrowDown />
              </span>
            </div>
          </Dropdown>
        </div>
        <Input 
          name="price" 
          label="Price" 
          type="number"
          step="0.01"
          placeholder="120"
          rules={{ 
            required: 'Price is required',
            validate: (value) => value > 0 || 'Price must be positive'
          }} 
          required 
        />
      </div>
      <Input 
        name="tax_amount" 
        label="Tax (%)" 
        type="number"
        step="0.01"
        placeholder="25"
        rules={{ 
          required: 'Tax is required',
          validate: (value) => (value >= 0 && value <= 100) || 'Tax must be between 0% and 100%'
        }} 
        required
      />
      {submitError && <div className={styles.errorMessage}>{submitError}</div>}
    </>
  );
};

interface EditPricingDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  pricingDetails: PricingDetails | null;
  onUpdate: (data: PricingDetails) => void;
  currencyOptions: string[];
  skus: Sku[];
  providerPricing: PricingDetails[];
}

const EditPricingDetailsModal: React.FC<EditPricingDetailsModalProps> = ({
  isOpen,
  onClose,
  pricingDetails,
  onUpdate,
  currencyOptions,
  skus,
  providerPricing,
}) => {
  const { state: { loginToken } } = useContext(LoginContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [selectedSku, setSelectedSku] = useState<string | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);

  useEffect(() => {
    if (pricingDetails && skus) {
      const matchingSku = skus.find(sku => sku.skuId === pricingDetails.sku_id);
      if (matchingSku) {
        setSelectedSku(`${matchingSku.skuId} - ${matchingSku.shortLabel}`);
      } else {
        setSelectedSku(`${pricingDetails.sku_id} - ${pricingDetails.sku}`);
      }
    }
  }, [pricingDetails, skus]);

  useEffect(() => {
    if (!isOpen && pricingDetails && skus) {
      const matchingSku = skus.find(sku => sku.skuId === pricingDetails.sku_id);
      if (matchingSku) {
        setSelectedSku(`${matchingSku.skuId} - ${matchingSku.shortLabel}`);
      } else {
        setSelectedSku(`${pricingDetails.sku_id} - ${pricingDetails.sku}`);
      }
    }
  }, [isOpen, pricingDetails, skus]);

  useEffect(() => {
    if (pricingDetails) {
      setSelectedCurrency(pricingDetails.currency);
    }
  }, [pricingDetails]);

  const formatDateForInput = (dateString: string | undefined) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().slice(0, 16);
  };

  const formatDateForDisplay = (dateString: string | undefined) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const skuOptions = useMemo(() => {
    if (!skus) return [];
    return skus.map(sku => `${sku.skuId} - ${sku.shortLabel}`);
  }, [skus]);

  const handleSkuSelect = (item: string) => {
    setSelectedSku(item);
  };

  const formattedPricingDetails = useMemo(() => {
    if (!pricingDetails) return null;
    return {
      ...pricingDetails,
      started_on: formatDateForInput(pricingDetails.started_on),
      ended_on: formatDateForInput(pricingDetails.ended_on),
      tax_amount: pricingDetails.tax_amount * 100,
    };
  }, [pricingDetails]);

  if (!formattedPricingDetails) return null;

  const validateEndDate = (value: string) => {
    const startDate = (document.querySelector('input[name="started_on"]') as HTMLInputElement)?.value;
    return !value || !startDate || new Date(value) > new Date(startDate) || 'End date must be after start date';
  };

  const handleCurrencySelect = (item: string) => {
    setSelectedCurrency(item);
  };

  const validateStartDate = (value: string) => {
    if (!value) return 'Start date is required';
    const selectedSkuId = selectedSku ? parseInt(selectedSku.split(' - ')[0], 10) : null;
    if (!selectedSkuId) return true;

    const overlappingPricing = providerPricing.find(pricing => 
      pricing.sku_id === selectedSkuId && 
      pricing.pricing_id !== pricingDetails?.pricing_id && 
      new Date(value) < new Date(pricing.ended_on)
    );

    if (overlappingPricing) {
      return `Pricing with SKU ID ${selectedSkuId} already exists. Start date must be after the end date of pricing ID ${overlappingPricing.pricing_id} (${formatDateForDisplay(overlappingPricing.ended_on)})`;
    }

    return true;
  };

  const handleSubmit = async (data: Partial<PricingDetails>) => {
    if (!loginToken || !pricingDetails) {
      console.error("No login token available or pricing details missing");
      toast.error("Authentication error");
      return;
    }
    setIsSubmitting(true);
    try {
      const [skuId, skuLabel] = selectedSku ? selectedSku.split(' - ') : [undefined, undefined];
      const submissionData = {
        ...data,
        sku_id: skuId ? parseInt(skuId, 10) : undefined,
        currency: selectedCurrency,
        tax_amount: (data.tax_amount || 0) / 100,
      };
      const response = await authFetch(loginToken, `/api/update-pricing/${pricingDetails.pricing_id}`, {
        method: "PUT",
        body: JSON.stringify(submissionData),
      });

      if (response.ok) {
        const updatedPricing = await response.json();
        const fullUpdatedPricing: PricingDetails = {
          ...pricingDetails,
          ...data,
          ...updatedPricing.pricing,
          sku_id: skuId ? parseInt(skuId, 10) : pricingDetails.sku_id,
          sku: skuLabel || pricingDetails.sku,
        };
        toast.success('Pricing details updated successfully');
        onUpdate(fullUpdatedPricing);
        onClose();
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Failed to update pricing details");
      }
    } catch (error) {
      toast.error(`Error updating pricing details: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    if (pricingDetails && skus) {
      const matchingSku = skus.find(sku => sku.skuId === pricingDetails.sku_id);
      if (matchingSku) {
        setSelectedSku(`${matchingSku.skuId} - ${matchingSku.shortLabel}`);
      } else {
        setSelectedSku(`${pricingDetails.sku_id} - ${pricingDetails.sku}`);
      }
    }
    onClose();
  };

  return (
    <Modal
      title="Edit Pricing Details"
      open={isOpen}
      onCancel={handleClose}
    >
      <Form<Partial<PricingDetails>>
        onSubmit={handleSubmit}
        defaultValues={formattedPricingDetails}
        footer={{
          onCancel: handleClose,
          submitText: "Update Pricing Details",
          loadingText: "Updating",
          isSubmitting,
        }}
      >
        <FormContent
          selectedSku={selectedSku}
          handleSkuSelect={handleSkuSelect}
          skuOptions={skuOptions}
          selectedCurrency={selectedCurrency}
          handleCurrencySelect={handleCurrencySelect}
          currencyOptions={currencyOptions}
          validateStartDate={validateStartDate}
          validateEndDate={validateEndDate}
          submitError={submitError}
        />
      </Form>
    </Modal>
  );
};

export default EditPricingDetailsModal;

interface EditPricingDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  pricingDetails: PricingDetails | null;
  onUpdate: (data: PricingDetails) => void;
  currencyOptions: string[];
  skus: Sku[];
  providerPricing: PricingDetails[];
}