import Header from "../../components/header/Header";
import InternalDeploymentWarningBanner from "../../components/internal-deployment-warning/InternalDeploymentWarningBanner";
import s from "./PreloginLayout.module.scss"; 
import { Toaster } from 'react-hot-toast';
export default function PreloginLayout({ children }) {
  return (
    <div className={s.prelayout}>
      <Toaster position="top-center" />
      <InternalDeploymentWarningBanner />
      <Header />
      <main>{children}</main>
    </div>
  );
}
