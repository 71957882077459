import React, { useContext, useState } from "react";
import Modal from "../../../../components/modal/Modal";
import { Form } from "../../../../components/Form/Form";
import Input from "../../../../components/inputs/Input/Input";
import { Provider } from "../ProvidersTable";
import { LoginContext } from "../../../../contexts/LoginContext/LoginContext";
import { authFetch } from "../../../../utils/utils";
import toast from "react-hot-toast";

const EditProviderDetailsModal: React.FC<EditProviderDetailsModalProps> = ({
  isOpen,
  onClose,
  provider,
  onSuccess,
}) => {
  const { state: { loginToken } } = useContext(LoginContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!provider) return null;
  
  const handleFormCancel = () => {
    onClose();
  };

  const handleSubmit = async (data: Partial<Provider>) => {
    if (!loginToken) {
      console.error("No login token available");
      toast.error("Authentication error");
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await authFetch(loginToken, `/api/update-provider/${provider.prid}`, {
        method: "PUT",
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success('Provider updated successfully');
        onSuccess();
        onClose();
      } else {
        toast.error(`Failed to update provider: ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`Error updating provider: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        title="Edit Provider Details"
        open={isOpen}
        onCancel={onClose}
      >
        <Form<Partial<Provider>>
          onSubmit={handleSubmit}
          defaultValues={provider}
          footer={{
            onCancel: handleFormCancel,
            submitText: "Update Provider Details",
            loadingText: "Updating",
            isSubmitting,
          }}
        >
          <Input 
            name="prid" 
            label="Provider ID" 
            disabled
          />
          <Input 
            name="label" 
            label="Provider Name"
            placeholder="(e.g. GI Alliance Texas)"
            rules={{ required: 'Provider name is required' }} 
          />
          <Input 
            name="description" 
            label="Description"
            placeholder="For additional context (e.g. GIA site in Texas. Key contact MD Adam Alias)"
            rules={{ required: 'Description is required' }}   
          />
        </Form>
      </Modal>
    </>
  );
};

export default EditProviderDetailsModal;

interface EditProviderDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  provider: Provider | null;
  onSuccess: () => void;
}