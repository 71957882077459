import { AppError, authFetch } from "../utils/utils";
import useDataAuth from "./useDataAuth";

export interface StaffAdmin {
  stfid: number;
  name: string;
  surname: string;
  salutation: string;
}

export default function useStaffAdmins(): UseStaffAdminsReturn {
  const { data, error } = useDataAuth(fetchStaffAdmins);
  return { staffAdmins: data, error };
}

async function fetchStaffAdmins(loginToken: string, signal: AbortSignal): Promise<StaffAdmin[]> {
  const res = await authFetch(loginToken, "/api/get-staff-admins", { signal });
  const { error, staffAdmins } = await res.json();
  if (error !== undefined) throw new AppError(res.status, error);
  return staffAdmins;
}

interface UseStaffAdminsReturn {
  staffAdmins: StaffAdmin[] | undefined;
  error: AppError | undefined;
}