import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PreloginLayout from '../../../../layouts/prelogin/PreloginLayout';
import Table from '../../../../components/table/Table';
import { TableButton } from '../../../../components/table/Table';
import SkuDetailsModal from './pricingModals/SkuDetailsModal';
import EditPricingDetailsModal from './pricingModals/EditPricingDetailsModal';
import useSkus from '../../../../hooks/useSkus';
import Header from '../../../../components/table/Header';
import AddPricingModal from './pricingModals/AddPricingModal';
import useCountries from '../../../../hooks/useCountries';

const PricingTable = () => {
  const { providerId }: UrlParams = useParams();
  const location = useLocation<{ providerCountry: string, label: string }>();
  const { countries } = useCountries();
  const { skus } = useSkus();

  const [isSkuDetailsModalOpen, setIsSkuDetailsModalOpen] = useState(false);
  const [isEditPricingModalOpen, setIsEditPricingModalOpen] = useState(false);
  const [isAddPricingModalOpen, setIsAddPricingModalOpen] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState<string | null>(null);
  const [providerPricing, setProviderPricing] = useState<PricingDetails[]>([]);
  const [selectedSkuId, setSelectedSkuId] = useState<number | null>(null);
  const [selectedPricingDetails, setSelectedPricingDetails] = useState<PricingDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const columns: Column[] = [
    { key: 'created_on', header: 'Created On', isDate: true },
    { key: 'started_on', header: 'Started On', isDate: true },
    { key: 'ended_on', header: 'Ended On', isDate: true },
    { 
      key: 'sku', 
      header: 'SKU',
      render: (_, rowData: PricingDetails) => getShortLabel(rowData.sku_id)
    },
    { key: 'description', header: 'Description' },
    { key: 'currency', header: 'Currency' },
    { key: 'price', header: 'Price' },
    { 
      key: 'tax_amount', 
      header: 'Tax', 
      render: (value: number) => `${(value * 100).toFixed(2).replace('.00', '')}%`
    },
    { 
      key: 'showSKUDetails',
      header: 'SKU Details',
      render: (_, rowData: PricingDetails) => (
        <TableButton 
          label="Show SKU Details" 
          onClick={() => {
            setSelectedSkuId(rowData.sku_id);
            setIsSkuDetailsModalOpen(true);
          }} 
        />
      )
    },
    {
      key: 'editPricingDetails',
      header: 'Edit Details',
      render: (_, rowData: PricingDetails) => (
        <TableButton 
          label="Edit Pricing Details" 
          onClick={() => handleEditPricing(rowData)} 
        />
      )
    }
  ];

  const getShortLabel = (skuId: number) => {
    const sku = skus?.find(s => s.skuId === skuId);
    return sku ? `${sku.skuId} - ${sku.shortLabel}` : '';
  };

  const getUniqueCurrencies = (): string[] => {
    if (!countries) return [];
    const currencySet = new Set(
      countries
        .map(country => country.currency)
        .filter((currency): currency is string => currency !== undefined)
    );
    return Array.from(currencySet);
  };

  const currencyOptions = getUniqueCurrencies();

  const fetchProviderPricing = async () => {
    try {
      const response = await fetch(`/api/get-all-provider-pricing/${providerId}`);
      if (!response.ok) throw new Error('Failed to fetch provider pricing data');
      const data = await response.json();
      setProviderPricing(data.all_pricing);
    } catch (err) {
      setError('Error fetching provider pricing data');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditPricing = (pricingDetails: PricingDetails) => {
    setSelectedPricingDetails(pricingDetails);
    setIsEditPricingModalOpen(true);
  };

  const handleUpdatePricing = () => {
    fetchProviderPricing();
  };

  const handleAddNewPricing = async () => {
    await fetchProviderPricing();
    setIsAddPricingModalOpen(false);
  };

  useEffect(() => {
    const providerCountry = location.state?.providerCountry;
    if (providerCountry && countries) {
      const country = countries.find(c => c.country === providerCountry);
      if (country?.currency) {
        setDefaultCurrency(country.currency);
      } else {
        setDefaultCurrency(null);
      }
    }
  }, [location.state, countries]);

  useEffect(() => {
    fetchProviderPricing();
  }, [providerId]);

  if (isLoading) return <PreloginLayout><div>Loading...</div></PreloginLayout>;
  if (error) return <PreloginLayout><div>Error: {error}</div></PreloginLayout>;
  
  return (
    <PreloginLayout>
      <Header 
        title={`Pricing Details of ${location.state?.label}`} 
        actionButton={{
          label: "Add Pricing",
          onClick: () => setIsAddPricingModalOpen(true)
        }}
      />
      <Table 
        columns={columns} 
        data={providerPricing}
        searchableFields={['pricing_id', 'sku_id', 'description', 'currency']}
        searchPlaceholder="Search by pricing ID, SKU ID, description, or currency..."
        defaultSort={{
          key: 'created_on',
          direction: 'desc'
        }}
      />
      <SkuDetailsModal
        open={isSkuDetailsModalOpen}
        onClose={() => setIsSkuDetailsModalOpen(false)}
        skuId={selectedSkuId || 0}
      />
      <EditPricingDetailsModal
        isOpen={isEditPricingModalOpen}
        onClose={() => setIsEditPricingModalOpen(false)}
        pricingDetails={selectedPricingDetails}
        onUpdate={handleUpdatePricing}
        currencyOptions={currencyOptions}
        skus={skus || []}
        providerPricing={providerPricing}
      />
      <AddPricingModal
        isOpen={isAddPricingModalOpen}
        onClose={() => setIsAddPricingModalOpen(false)}
        onAdd={handleAddNewPricing}
        providerId={providerId}
        currencyOptions={currencyOptions}
        defaultCurrency={defaultCurrency}
        skus={skus || []}
        providerPricing={providerPricing}
      />
    </PreloginLayout>
  );
};

export default PricingTable;

interface UrlParams {
  providerId: string;
}

export interface PricingDetails {
  pricing_id: number;
  created_on: string;
  started_on: string;
  ended_on: string;
  prid: number;
  sku_id: number;
  sku: string;
  currency: string;
  price: number;
  description: string;
  tax_amount: number;
}

interface Column {
  key: string;
  header: string;
  isDate?: boolean;
  isId?: boolean;
  render?: (value: any, rowData: PricingDetails) => React.ReactNode;
}
