import { useContext, useEffect, useReducer, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import SuccessDialog from "../../../components/dialogs/SuccessDialog/SuccessDialog";
import ErrorDialog from "../../../components/errors/ErrorDialog/ErrorDialog";
import FormErrorMessage from "../../../components/errors/FormErrorMessage/FormErrorMessage";
import LabelledCheckbox from "../../../components/inputs/LabelledCheckbox/LabelledCheckbox";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import PreloginLayout from "../../../layouts/prelogin/PreloginLayout";
import { validationRegEx } from "../../../utils/FormValidation";
import { AppError, authFetch, devLog } from "../../../utils/utils";
import IFTAInput from "../../register/IFTAElements/IFTAInput/IFTAInput";
import IFTAPhoneInput from "../../register/IFTAElements/IFTAPhoneInput/IFTAPhoneInput";
import IFTACountryDropdown from "../../register/IFTAElements/IFTASelect/IFTACountrySelect";
import IFTARegionSelect from "../../register/IFTAElements/IFTASelect/IFTARegionSelect";
import s from "../DashAdmin.module.scss";
import useSubmitReducer from "../../../hooks/useSubmitReducer";

interface AddProviderFormFieldValues {
  provider: {
    label: string;
    requiresEmailLabel: boolean;
    emailLabel: string;
    description: string;
    domain: string;
    country: string;
    requiresVideoLink: boolean;
    videoLink: string;
  },
  requiresShipping: boolean;
  shipping: {
    address: string;
    name: string;
    city: string;
    zip: string;
    state: string;
    country: string;
    email: string;
    phone: string;
  },
}

export default function AddProvider2(): JSX.Element {
  const { state: { loginToken, staff } } = useContext(LoginContext);

  const form = useForm<AddProviderFormFieldValues>({
    defaultValues: {
      provider: {
        label: "",
        requiresEmailLabel: false,
        emailLabel: "",
        description: "",
        domain: "",
        country: "",
        requiresVideoLink: false,
        videoLink: "",
      },
      requiresShipping: false,
      shipping: {
        address: "",
        name: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        email: "",
        phone: "",
      }
    }
  });

  const { handleSubmit, watch } = form;

  const [state,dispatch] = useSubmitReducer(form);

  const onSubmit = async (data: AddProviderFormFieldValues) => {
    dispatch({type: "PROCESSING"});
    try {
      if (!loginToken) return;
      if (watch("requiresShipping")) await createProviderWithShipping(loginToken,data);
      else await createProviderWithoutShipping(loginToken,data);
      dispatch({type: "COMPLETE"});
    } catch (err) {
      dispatch({type: "ERROR", payload: err });
    }
  }

  useEffect(() => {
    if (!staff?.permissions.dashAdmin) dispatch({type: "ERROR", payload: new AppError(403,"Forbidden")});
  },[staff]);

  return (
    <PreloginLayout>
      <div className={s.dashAdmin}>
        <h1>Add Provider</h1>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ProviderInputs form={form} />
            <LabelledCheckbox form={form} name="requiresShipping">Add Shipping Address?</LabelledCheckbox>
            { watch("requiresShipping") && <ShippingInputs form={form} />}
            <button type="submit">Submit</button>
          </form>
        </div>
        <SuccessDialog
          isOpen={state.complete}
			  	onClose={() => dispatch({type: "RESET"})}
			  	title="Provider Successfully Added to Database"
        />
        <ErrorDialog
			  	error={state.error?.message ?? ""}
			  	onClose={() => dispatch({type: "RESET"})}
        />
      </div>
    </PreloginLayout>
  )
}

const ProviderInputs = ({form}: {form: UseFormReturn<AddProviderFormFieldValues,any>}) => {
  const { register, watch, formState: { errors } } = form;
  return (
    <>
      <IFTAInput id="provider-label" label="Provider label" reg={register("provider.label",{required: "Provider label is required"})} />
      <FormErrorMessage errors={errors} name="provider.label" />
      <LabelledCheckbox form={form} name="provider.requiresEmailLabel">Apply different label in emails?</LabelledCheckbox>
      { watch("provider.requiresEmailLabel") && <IFTAInput id="emailLabel" label="Provider email label" 
      reg={register("provider.emailLabel",{required: "Provider email label is required"})} />}
      <FormErrorMessage errors={errors} name="provider.emailLabel" />
      <IFTAInput id="description" label="description" reg={register("provider.description",{required: "Description is required"})}/>
      <FormErrorMessage errors={errors} name="provider.description" />
      <IFTAInput id="domain" label="domain" reg={register("provider.domain",{
        required: "Provider domain is required",
        pattern: {
          value: validationRegEx.domainName,
          message: "Invalid domain name",
        }
      })}/>
      <FormErrorMessage errors={errors} name="provider.domain" />
      <IFTACountryDropdown id="country" form={form} name="provider.country" label="Country" valueType="short" rules={{required: "Provider country is required"}} />
      <FormErrorMessage errors={errors} name="provider.country" />
      <LabelledCheckbox form={form} name="provider.requiresVideoLink">Use non-default video link in emails?</LabelledCheckbox>
      { watch("provider.requiresVideoLink") && <IFTAInput id="videoLink" label="Video link"reg={register("provider.videoLink",{
        required: "Video link is required",
        pattern: {
          value: validationRegEx.domainName,
          message: "Invalid video link"
        }
      })} />}
      <FormErrorMessage errors={errors} name="provider.requiresVideoLink" />
    </>
  );
}

const ShippingInputs = ({form}: {form: UseFormReturn<AddProviderFormFieldValues,any>}) => {
  const { register, watch, formState: { errors } } = form;
  return (
    <>
      <IFTAInput id="ship-name" label="Name" reg={register("shipping.name",{required: "Shipping name is required"})} />
      <FormErrorMessage errors={errors} name="shipping.name"/>
      <IFTAInput id="ship-email" label="Email Address" reg={register("shipping.email",{required: "Shipping email address is required"})} />
      <FormErrorMessage errors={errors} name="shipping.email"/>
      <IFTAPhoneInput id="ship-phone" label="Phone Number" form={form} name="shipping.phone" rules={{
        required: "Phone number is required",
      }} />
      <IFTAInput id="ship-address" label="Address" reg={register("shipping.address",{required: "Shipping address is required"})} />
      <FormErrorMessage errors={errors} name="shipping.address"/>
      <IFTAInput id="ship-city" label="City" reg={register("shipping.city",{required: "Shipping city is required"})} />
      <FormErrorMessage errors={errors} name="shipping.city"/>
      <IFTAInput id="ship-zip" label="Postal code" reg={register("shipping.zip",{required: "Shipping postal code is required"})} />
      <FormErrorMessage errors={errors} name="shipping.zip"/>
      <IFTACountryDropdown id="ship-country" form={form} label="Country" name="shipping.country" valueType="short" />
      <FormErrorMessage errors={errors} name="shipping.country" />
      {/* <IFTARegionSelect id="ship-state" form={form} label="State/Region" name="shipping.state" valueType="short" country={watch("provider.country")} /> */}
      <IFTAInput id="ship-state" label="State/Region" name="shipping.state" reg={register("shipping.state",{required: "Shipping state is required"})} />
      <FormErrorMessage errors={errors} name="shipping.state" />
    </> 
  );
}

const AddProviderError = ({error}: {error: AppError}) => {
  return (
    <div>
      <h1>Error {error.code}</h1>
      <p>{error.message}</p>
      <button className={s.btnPrimary} onClick={() => window.location.reload()}>Return</button>
    </div>
  )
}

const createProviderWithoutShipping = async (loginToken: string, data: AddProviderFormFieldValues): Promise<void> => {
  const body: PostBodyWithoutShipping = {
    provider: data.provider,
  };
  const res = await authFetch(loginToken,"/api/provider",{
    method: "POST",
    body: JSON.stringify(body)
  });
  const { error }: { error: string | undefined } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return;
}

const createProviderWithShipping = async (loginToken: string, data: AddProviderFormFieldValues): Promise<void> => {
  const res = await authFetch(loginToken,"/api/provider",{
    method: "POST",
    body: JSON.stringify(createPostBody(data))
  });
  const { error }: { error: string | undefined } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return;
}

const createPostBody = (data: AddProviderFormFieldValues): PostBodyWithoutShipping | PostBodyWithShipping => {
  let body: PostBodyWithShipping | PostBodyWithoutShipping = {
    provider: data.provider,
  }
  if (data.requiresShipping) body = {
    ...body,
    shipping: {
      shippingName: data.shipping.name,
      shippingEmail: data.shipping.email,
      shippingPhone: data.shipping.phone,
      shippingAddress1: data.shipping.address,
      shippingCity: data.shipping.city,
      shippingCountry: data.provider.country,
      shippingState: data.shipping.state,
      shippingZip: data.shipping.zip,
    }
  }
  return body;
}

interface ProviderPostData {
  label: string;
  emailLabel: string;
  description: string;
  domain: string;
  country: string;
  videoLink: string;
}

interface ShippingPostData {
  shippingAddress1: string;
  shippingName: string;
  shippingCity: string;
  shippingZip: string;
  shippingState: string;
  shippingCountry: string;
  shippingEmail: string;
  shippingPhone: string;
}

interface PostBodyWithoutShipping {
  provider: ProviderPostData;
}

interface PostBodyWithShipping {
  provider: ProviderPostData;
  shipping: ShippingPostData;
}