import React, { useContext, useState, useEffect } from "react";
import Modal from "../../../../../components/modal/Modal";
import { Form } from "../../../../../components/Form/Form";
import Input from "../../../../../components/inputs/Input/Input";
import styles from "./EditShippingDetailsModal.module.scss";
import { ShippingDetails } from "../ShippingTable";
import { LoginContext } from "../../../../../contexts/LoginContext/LoginContext";
import { authFetch } from "../../../../../utils/utils";
import { Dropdown } from "../../../../../components/dropdown/Dropdown";
import useCountries from "../../../../../hooks/useCountries";
import { IoIosArrowDown } from 'react-icons/io';
import toast from "react-hot-toast";

const EditShippingDetailsModal: React.FC<EditShippingDetailsModalProps> = ({
  isOpen,
  onClose,
  shippingDetails,
  onUpdate,
}) => {
  const { state: { loginToken } } = useContext(LoginContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { countries } = useCountries();
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);

  useEffect(() => {
    if (shippingDetails && countries) {
      const matchingCountry = countries.find(c => c.country === shippingDetails.shippingCountry);
      if (matchingCountry) {
        setSelectedCountry(matchingCountry.label);
      }
    }
  }, [shippingDetails, countries]);

  if (!shippingDetails) return null;

  const handleFormCancel = () => {
    onClose();
  };

  const handleCountrySelect = (label: string) => {
    setSelectedCountry(label);
  };

  const handleSubmit = async (data: Partial<ShippingDetails>) => {
    if (!loginToken) {
      console.error("No login token available");
      toast.error("Authentication error");
      return;
    }
    setIsSubmitting(true);
    try {
      const selectedCountryData = countries?.find(c => c.label === selectedCountry);
      const submissionData = {
        ...data,
        shippingCountry: selectedCountryData?.country,
      };

      const response = await authFetch(loginToken, `/api/update-shipping/${shippingDetails.shipId}`, {
        method: "PUT",
        body: JSON.stringify(submissionData),
      });

      if (response.ok) {
        const updatedShipping = await response.json();
        const fullUpdatedShipping: ShippingDetails = {
          ...shippingDetails,
          ...submissionData,
          ...updatedShipping.shipping_info
        };
        toast.success('Shipping details updated successfully');
        onUpdate(fullUpdatedShipping);
        onClose();
      } else {
        toast.error(`Failed to update shipping details: ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`Error updating shipping details: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title="Edit Shipping Details"
      open={isOpen}
      onCancel={onClose}
    >
      <Form<Partial<ShippingDetails>>
        onSubmit={handleSubmit}
        defaultValues={shippingDetails}
        footer={{
          onCancel: handleFormCancel,
          submitText: "Update Shipping Details",
          loadingText: "Updating",
          isSubmitting,
        }}
      >
        <div className={styles.inputGroup}>
          <Input 
            name="shipId" 
            label="Shipping ID" 
            disabled
          />
          <Input 
            name="shippingName" 
            label="Shipping Name" 
            placeholder="Recipient Name (e.g. 'John Doe')"
            rules={{ required: 'Shipping name is required' }} 
            required
          />
        </div>
        <div className={styles.inputGroup}>
          <Input 
            name="shippingEmail" 
            label="Email" 
            type="email"
            placeholder="Email (e.g. 'johndoe@example.com')"
            rules={{ 
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address"
              }
            }} 
            required
          />
          <Input 
            name="shippingPhone" 
            label="Phone Number" 
            placeholder="Phone Number (e.g. '+1 489 234 5678')"
            rules={{ required: 'Phone number is required' }} 
            required
          />
        </div>
        <Input 
          name="shippingAddress1" 
          label="Address" 
          placeholder="Address (e.g. '123 Main Street')"
          rules={{ required: 'Address is required' }} 
          required
        />
        <div className={styles.inputGroup}>
          <Input 
            name="shippingCity" 
            label="City" 
            placeholder="City (e.g. 'San Francisco')"
            rules={{ required: 'City is required' }} 
            required
          />
          <Input 
            name="shippingState" 
            label="State"
            placeholder="State (e.g. 'California')"
            rules={{ required: selectedCountry == "United States of America" ? 'State is required' : undefined }}
            disabled={selectedCountry !== "United States of America"}
            required={selectedCountry === "United States of America"}
          />
        </div>
        <div className={styles.inputGroup}>
          <Input 
            name="shippingZip" 
            label="Postal Code" 
            placeholder="Postal Code (e.g. '95132')"
            rules={{ required: 'Postal code is required' }} 
            required
          />
          <div className={styles.dropdownWrapper}>
            <label htmlFor="shippingCountry" className={styles.label}>Country *</label>
            <Dropdown
              menu={{
                items: countries?.map(country => country.label) || [],
                onSelect: handleCountrySelect,
              }}
              size="small"
            >
              <div className={styles.preferenceValue}>
                {selectedCountry || 'Select Country'}
                <span className={styles.iconWrapper}>
                  <IoIosArrowDown />
                </span>
              </div>
            </Dropdown>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default EditShippingDetailsModal;

interface EditShippingDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  shippingDetails: ShippingDetails | null;
  onUpdate: (data: ShippingDetails) => void;
}
