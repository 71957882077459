import React from 'react';
import styles from './Switch.module.scss';

interface SwitchProps {
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  size?: 'default' | 'small';
  onChange?: (checked: boolean) => void;
}

const Switch: React.FC<SwitchProps> = ({
  checked,
  defaultChecked = false,
  disabled = false,
  size = 'default',
  onChange
}) => {
  const [isChecked, setIsChecked] = React.useState(
    checked !== undefined ? checked : defaultChecked
  );

  React.useEffect(() => {
    if (checked !== undefined) {
      setIsChecked(checked);
    }
  }, [checked]);

  const handleClick = () => {
    if (disabled) return;
    
    const newChecked = !isChecked;
    if (checked === undefined) {
      setIsChecked(newChecked);
    }
    onChange?.(newChecked);
  };

  const switchClasses = [
    styles.switch,
    styles[size],
    isChecked ? styles.checked : '',
    disabled ? styles.disabled : ''
  ].filter(Boolean).join(' ');

  return (
    <button
      type="button"
      role="switch"
      aria-checked={isChecked}
      onClick={handleClick}
      disabled={disabled}
      className={switchClasses}
    >
      <div className={styles.handle} />
    </button>
  );
};

export default Switch;