import React from 'react';
import styles from './NavBar.module.scss';

interface NavItem {
  title: string;
  active: boolean;
}

interface NavBarProps {
  items: NavItem[];
  className?: string;
  onItemClick?: (index: number) => void;
}

const NavBar: React.FC<NavBarProps> = ({ items, className, onItemClick, ...props }) => {
  return (
    <aside className={styles.aside}>
      <nav className={`${styles.nav} ${className || ''}`} {...props}>
        {items.map((item, index) => (
          <button
            key={item.title}
            className={`${styles.link} ${item.active ? styles.active : ''}`}
            onClick={() => onItemClick && onItemClick(index)}
          >
            {item.title}
          </button>
        ))}
      </nav>
    </aside>
  );
};

export default NavBar;
