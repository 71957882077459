import React from 'react';
import { UseFormProps, FieldValues, useForm, FormProvider } from 'react-hook-form';
import { Button } from '../buttons/Button/Button';
import styles from './Form.module.scss';


export function Form<TFieldValues extends FieldValues>({
  onSubmit,
  children,
  footer,
  ...formProps
}: FormProps<TFieldValues>) {
  const methods = useForm<TFieldValues>(formProps);

  const FormFooter: React.FC<FormFooterProps> = ({ onCancel, submitText, loadingText, isSubmitting }) => (
    <div className={styles.formFooter}>
      <Button variant="outline" onClick={(e) => { e.preventDefault(); onCancel(); }} disabled={isSubmitting}>Cancel</Button>
      <Button type="submit" loading={isSubmitting}>{isSubmitting ? loadingText : submitText}</Button>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.formContent}>
          {children}
        </div>
        {footer && <FormFooter {...footer} />}
      </form>
    </FormProvider>
  );
}


interface FormFooterProps {
  onCancel: () => void;
  submitText: string;
  loadingText?: string;
  isSubmitting?: boolean;
}

interface FormProps<TFieldValues extends FieldValues> extends UseFormProps<TFieldValues> {
  onSubmit: (data: TFieldValues) => void;
  children: React.ReactNode;
  footer?: FormFooterProps;
}
