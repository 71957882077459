import { useState, useEffect } from 'react';
import { AppError } from "../utils/utils";
import { Sku, createSku } from './useSkus';

const useSkuDetails = (skuId: number, open: boolean) => {
  const [skuDetails, setSkuDetails] = useState<Sku | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<AppError | null>(null);

  useEffect(() => {
    const fetchSkuDetails = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`/api/skus/${skuId}`);
        if (!response.ok) {
          throw new AppError(response.status, 'Failed to fetch SKU details');
        }
        const data = await response.json();
        setSkuDetails(createSku(data.skus[0]));
      } catch (err) {
        setError(err instanceof AppError ? err : new AppError(500, 'Error fetching SKU details'));
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (open) {
      fetchSkuDetails();
    }
  }, [open, skuId]);

  return { skuDetails, isLoading, error };
};

export default useSkuDetails;