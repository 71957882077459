import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PreloginLayout from '../../../layouts/prelogin/PreloginLayout';
import Table from '../../../components/table/Table';
import { TableButton } from '../../../components/table/Table';
import { Button } from '../../../components/buttons/Button/Button';
import EditProviderDetailsModal from './providersModals/EditProviderDetailsModal';
import EditPreferencesModal from './providersModals/EditPreferencesModal';
import styles from './ProvidersTable.module.scss';
import { LoginContext } from '../../../contexts/LoginContext/LoginContext';
import { authFetch } from '../../../utils/utils';
import toast from 'react-hot-toast';
import Header from '../../../components/table/Header';

const ProvidersTable: React.FC = () => {
  const [providers, setProviders] = useState<Provider[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { state: { loginToken } } = useContext(LoginContext);
  const history = useHistory();

  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false);

  const fetchProviders = async () => {
    if (!loginToken) {
      setError('No login token available');
      return;
    }

    try {
      const response = await authFetch(loginToken, '/api/get-all-providers');
      if (response.ok) {
        const data = await response.json();
        if (data.error) {
          throw new Error(data.error);
        }
        setProviders(data.providers);
      } else {
        throw new Error(`Failed to fetch providers: ${response.statusText}`);
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Unknown error occurred';
      setError(message);
      toast.error(`Error loading providers: ${message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProviders();
  }, [loginToken]);

  const handleCloseDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setTimeout(() => setSelectedProvider(null), 300);
  };

  const handleClosePreferencesModal = () => {
    setIsPreferencesModalOpen(false);
    setSelectedProvider(null);
  };

  const handleNavigateToPricing = (rowData: Provider) => {
    history.push(`/dash-admin/providers/${rowData.prid}/pricing`, { providerCountry: rowData.country, label: rowData.label });
  };

  const columns: Column[] = [
    { key: 'createdOn', header: 'Created On', isDate: true },
    { key: 'prid', header: 'Provider ID' },
    { key: 'label', header: 'Provider Name' },
    { key: 'description', header: 'Description' },
    { 
      key: 'editDetails', 
      header: 'Edit Provider Details', 
      render: (_, rowData) => (
        <TableButton 
          label="Edit Details" 
          onClick={() => {
            setSelectedProvider(rowData);
            setIsDetailsModalOpen(true);
          }} 
        />
      )
    },
    { 
      key: 'editPreferences', 
      header: 'Edit Preferences', 
      render: (_, rowData) => (
        <TableButton 
          label="Edit Preferences" 
          onClick={() => {
            setSelectedProvider(rowData);
            setIsPreferencesModalOpen(true);
          }} 
        />
      )
    },
    {
      key: 'viewMore', 
      header: 'View More Details', 
      render: (_, rowData) => (
        <div className={styles.buttonGroup}>
          <Link to={`/dash-admin/providers/${rowData.prid}/shipping`}>
            <TableButton label="Shipping"/>
          </Link>
          <TableButton 
            label="Pricing" 
            onClick={() => handleNavigateToPricing(rowData)}
          />
        </div>
      )
    },
  ];

  return (
    <PreloginLayout>
      <Header title="Providers Table" />
      {error ? (
        <p>Error loading providers: {error}</p>
      ) : isLoading ? (
        <p>Loading providers...</p>
      ) : (
        <Table 
          columns={columns} 
          data={providers} 
          searchableFields={['prid', 'label', 'description']}
          searchPlaceholder="Search by ID, label, or description..."
          defaultSort={{
            key: 'createdOn',
            direction: 'desc'
          }}
        />
      )}
      <EditProviderDetailsModal
        isOpen={isDetailsModalOpen}
        onClose={handleCloseDetailsModal}
        provider={selectedProvider}
        onSuccess={fetchProviders}
      />
      <EditPreferencesModal
        isOpen={isPreferencesModalOpen}
        onClose={handleClosePreferencesModal}
        providerId={selectedProvider?.prid || 0}
      />
    </PreloginLayout>
  );
};

export default ProvidersTable;

export interface Provider {
  prid: number;
  label: string;
  description: string;
  country: string;
  domain: string;
  logo: string | null;
  timezone: string;
  deployment: string | null;
  createdOn: string;
  emailLabel: string;
  videoLink: string | null;
}

interface Column {
  key: string;
  header: string;
  isDate?: boolean;
  render?: (value: any, rowData: Provider) => React.ReactNode;
}
