import React from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Tooltip } from '../../tooltip/Tooltip';
import styles from './Input.module.scss';

interface InputProps extends UseControllerProps {
  label: string;
  type?: string;
  placeholder?: string;
  description?: boolean;
  icon?: React.ReactNode;
  width?: string | number;
  fixedWidth?: string | number;
  disabled?: boolean;
  step?: string | number;
  required?: boolean;
  tooltip?: string;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
}

const Input: React.FC<InputProps> = ({ 
  label,
  type = 'text',
  placeholder,
  description,
  icon,
  width,
  fixedWidth,
  disabled = false,
  step,
  required = false,
  tooltip,
  tooltipPosition = 'right',
  ...props 
}) => {
  const {
    field,
    fieldState: { error }
  } = useController(props);

  const InputComponent = description ? 'textarea' : 'input';
  const containerStyle = fixedWidth 
    ? { width: typeof fixedWidth === 'number' ? `${fixedWidth}px` : fixedWidth } 
    : (width ? { width: typeof width === 'number' ? `${width}px` : width } : {});

  const value = field.value === null ? '' : field.value;

  return (
    <div className={`${styles.inputContainer} ${disabled ? styles.disabled : ''} ${fixedWidth ? styles.fixedWidth : ''}`} style={containerStyle}>
      <div className={styles.labelContainer}>
        <span className={styles.label}>
          {label}
          {required && <span className={styles.requiredAsterisk}>*</span>}
        </span>
        {tooltip && <Tooltip text={tooltip} position={tooltipPosition} />}
      </div>
      <div className={styles.inputWrapper}>
        {icon && <span className={styles.icon}>{icon}</span>}
        <InputComponent
          {...field}
          value={value}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          step={step}
          required={required}
          className={`${styles.input} ${description ? styles.description : ''} ${error ? styles.error : ''}`}
        />
      </div>
      {error && <span className={styles.errorMessage}>{error.message}</span>}
    </div>
  );
};

export default Input;
