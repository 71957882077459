import React, { ReactElement } from 'react';
import styles from './Button.module.scss';

type ButtonVariant = 'primary' | 'secondary' | 'ghost' | 'outline';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: 'default' | 'icon';
  disabled?: boolean;
  loading?: boolean;
  icon?: ReactElement;
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  disabled = false,
  loading = false,
  size = 'default',
  icon,
  children,
  className,
  ...props
}) => {
  return (
    <button
      className={`${styles.button} ${styles[`button--${variant}`]} ${styles[`button--${size}`]}  ${disabled ? styles['button--disabled'] : ''} ${loading ? styles['button--loading'] : ''} ${className || ''}`}
      disabled={disabled || loading}
      {...props}
    >
      {loading && <div className={styles.loader}></div>}
      {icon}
      {children}
    </button>
  );
};