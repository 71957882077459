import { AppError, authFetch } from "../utils/utils";
import useDataAuth from "./useDataAuth";

export default function useProviderPreferenceKeys(): UseProviderPreferenceKeysReturn {
    const { data, error } = useDataAuth(fetchProviderPreferenceKeys);
    return { preferenceKeys: data, error };
}

async function fetchProviderPreferenceKeys(loginToken: string, signal: AbortSignal): Promise<PreferenceKey[]> {
    const res = await authFetch(loginToken, "/api/provider-preference-keys", { signal });
    const { error, preference_keys } = await res.json();
    if (error !== undefined) throw new AppError(res.status, error);
    return preference_keys;
}

interface PreferenceKey {
    ppId: number;
    answerFormat: string;
    dataType: number;
    defaultValue: number | null;
    setViaDashAdmin: boolean | null;
    text: string;
}

interface UseProviderPreferenceKeysReturn {
    preferenceKeys: PreferenceKey[] | undefined;
    error: AppError | undefined;
}
