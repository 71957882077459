import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PreloginLayout from '../../../../layouts/prelogin/PreloginLayout';
import Table from '../../../../components/table/Table';
import { TableButton } from '../../../../components/table/Table';
import EditShippingDetailsModal from './shippingModals/EditShippingDetailsModal';
import Header from '../../../../components/table/Header';
import AddShippingModal from './shippingModals/AddShippingModal';

const ShippingTable = () => {
  const { providerId }: UrlParams = useParams();
  const [providerShipping, setProviderShipping] = useState<ShippingDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchProviderShipping = async () => {
    try {
      const response = await fetch(`/api/get-provider-shipping-dashboard/${providerId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch provider shipping data');
      }
      const data = await response.json();
      setProviderShipping(data.providerShipping);
    } catch (err) {
      setError('Error fetching provider shipping data');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProviderShipping();
  }, [providerId]);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedShipping, setSelectedShipping] = useState<ShippingDetails | null>(null);

  const handleEditClick = (shipping: ShippingDetails) => {
    setSelectedShipping(shipping);
    setIsEditModalOpen(true);
  };

  const handleUpdateShipping = (updatedData: ShippingDetails) => {
    console.log("Received updated data:", updatedData);
    setProviderShipping(prevShipping => 
      prevShipping.map(shipping => 
        shipping.shipId === updatedData.shipId ? updatedData : shipping
      )
    );
    setIsEditModalOpen(false);
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const handleAddClick = () => {
    setIsAddModalOpen(true);
  };

  const handleAddShipping = (newShipping: ShippingDetails) => {
    fetchProviderShipping();
    setIsAddModalOpen(false);
  };

  const columns: Column[] = [
    { 
      key: 'createdOn', 
      header: 'Created On', 
      isDate: true,
      render: (value) => value ? new Date(value).toLocaleDateString() : 'N/A'
    },
    { key: 'shipId', header: 'ID' },
    { key: 'shippingName', header: 'Name' },
    { key: 'shippingEmail', header: 'Email' },
    { key: 'shippingPhone', header: 'Phone Number' },
    { key: 'shippingAddress1', header: 'Address' },
    { key: 'shippingCity', header: 'City' },
    { key: 'shippingState', header: 'State' },
    { key: 'shippingZip', header: 'Zip Code' },
    { key: 'shippingCountry', header: 'Country'},
    { 
      key: 'editShippingDetails', 
      header: 'Edit Shipping Details', 
      render: (_, rowData: ShippingDetails) => (
        <TableButton label="Edit Details" onClick={() => handleEditClick(rowData)} />
      )
    },
  ];
 
  if (isLoading) {
    return <PreloginLayout><div>Loading...</div></PreloginLayout>;
  }

  if (error) {
    return <PreloginLayout><div>Error: {error}</div></PreloginLayout>;
  }

  return (
    <PreloginLayout>
      <Header 
        title={`Shipping Addresses of Provider ID ${providerId}`}
        actionButton={{
          label: "Add Shipping",
          onClick: handleAddClick
        }}
      />
      <Table 
        columns={columns} 
        data={providerShipping}
        searchableFields={['shipId', 'shippingName', 'Email', 'shippingPhone', 'shippingCity', 'shippingCountry']}
        searchPlaceholder="Search by ID, name, email, phone, city, or country..."
      />
      <EditShippingDetailsModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        shippingDetails={selectedShipping}
        onUpdate={handleUpdateShipping}
      />
      <AddShippingModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        providerId={providerId}
        onAdd={handleAddShipping}
      />
    </PreloginLayout>
  );
};

export default ShippingTable;

interface UrlParams {
  providerId: string;
}

export interface ShippingDetails {
  prid: number;
  shipId: number;
  createdOn: string;
  shippingName: string;
  shippingEmail: string;
  shippingPhone: string;
  shippingAddress1: string;
  shippingCity: string;
  shippingZip: string;
  shippingState: string | null;
  shippingCountry: string;
}

interface Column {
  key: string;
  header: string;
  isDate?: boolean;
  render?: (value: any, rowData: ShippingDetails) => React.ReactNode;
}
