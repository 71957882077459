import React, { useState, useEffect, useContext, useMemo } from 'react';
import Modal from '../../../../components/modal/Modal';
import { Form } from '../../../../components/Form/Form';
import { Dropdown } from '../../../../components/dropdown/Dropdown';
import { Button } from '../../../../components/buttons/Button/Button';
import { HiOutlinePlus } from "react-icons/hi";
import { IoIosArrowDown } from 'react-icons/io';
import styles from './EditPreferencesModal.module.scss';
import { LoginContext } from '../../../../contexts/LoginContext/LoginContext';
import { authFetch } from '../../../../utils/utils';
import useProviderPreferenceKeys from '../../../../hooks/useProviderPreferenceKeys';
import toast from 'react-hot-toast';

const EditPreferencesModal: React.FC<EditPreferencesModalProps> = ({
  isOpen,
  onClose,
  providerId,
}) => {
  const [providerPreferences, setProviderPreferences] = useState<ProviderPreference[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [changes, setChanges] = useState<PreferenceChanges>({ add: [], update: [] });
  const { state: { loginToken } } = useContext(LoginContext);
  const { preferenceKeys } = useProviderPreferenceKeys();

  const preferences: Preference[] = useMemo(() => {
    if (!preferenceKeys) return [];
    
    return preferenceKeys
      .filter(key => key.setViaDashAdmin === true)
      .map(key => {
        let options;
        // For boolean type (dataType: 0), set the standard Yes/No options
        if (key.dataType === 0) {
          options = [
            { label: "No", value: "0" },
            { label: "Yes", value: "1" }
          ];
        } else if (key.answerFormat === "Integer - day of the month") {
          options = Array.from({length: 31}, (_, i) => ({ 
            label: String(i + 1), 
            value: String(i + 1) 
          }));
        }
  
        let answerFormat = key.answerFormat;
        if (key.dataType === 0) {
          answerFormat = "Boolean, 0 = false, 1 = true";
        } else if (key.answerFormat === "Integer - day of the month") {
          answerFormat = key.answerFormat;
        } else if (key.dataType === 1 && key.answerFormat.includes("SAML")) {
          answerFormat = "Integer, 1 = password, 2 = SAML, 3 = Magic Link";
        }
  
        return {
          ppId: key.ppId,
          text: key.text,
          answerFormat,
          options: options || [],
          defaultValue: key.defaultValue?.toString() || "0"
        };
      })
      .sort((a, b) => a.ppId - b.ppId);
  }, [preferenceKeys]);

  useEffect(() => {
    const fetchProviderPreferences = async () => {
      if (!loginToken) {
        console.error("No login token available");
        return;
      }
      setIsLoading(true);
      try {
        const response = await authFetch(loginToken, `/api/provider-preferences-dashboard?prid=${providerId}`);
        if (response.ok) {
          const data = await response.json();
          // filter data.preferences where setViaDashAdmin in preferenceKeys is true
          const filteredPreferences = data.preferences.filter((pp: ProviderPreference) => preferenceKeys?.find(key => key.ppId === pp.ppId && key.setViaDashAdmin === true));
          setProviderPreferences(filteredPreferences);
        } else {
          console.error("Failed to fetch provider preferences");
        }
      } catch (error) {
        console.error("Error fetching provider preferences:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen && providerId) {
      fetchProviderPreferences();
      setChanges({ add: [], update: [] }); // Reset changes when modal opens
    }
  }, [providerId, isOpen, loginToken]);

  const availablePreferences = preferences.filter(
    pref => !providerPreferences.some(pp => pp.ppId === pref.ppId)
  );

  const handleAddPreference = (selectedPreference: string) => {
    const [ppId, text] = selectedPreference.split(' - ');
    const newPreference = preferences.find(p => p.ppId === parseInt(ppId));
    if (newPreference) {
      const newPrefVal: ProviderPreference = {
        ppValId: Math.max(...providerPreferences.map(pp => pp.ppValId), 0) + 1,
        ppId: newPreference.ppId,
        prid: providerId,
        set_on: new Date().toISOString(),
        val: newPreference.defaultValue,
      };
      setProviderPreferences([...providerPreferences, newPrefVal]);
      setChanges(prev => ({
        ...prev,
        add: [...prev.add, { ppId: newPreference.ppId, val: newPreference.defaultValue }]
      }));
    }
  };

  const handlePreferenceChange = (ppValId: number, value: string) => {
    setProviderPreferences(
      providerPreferences.map(pp =>
        pp.ppValId === ppValId ? { ...pp, val: value } : pp
      )
    );
    setChanges(prev => ({
      ...prev,
      update: [...prev.update.filter(u => u.ppValId !== ppValId), { ppValId, val: value }]
    }));
  };

  const handleSubmit = async () => {
    if (!loginToken) {
      console.error("No login token available");
      toast.error("Authentication error");
      return;
    }
    try {
      const response = await authFetch(loginToken, `/api/provider-preferences-dashboard/${providerId}`, {
        method: 'PUT',
        body: JSON.stringify(changes),
      });
      if (response.ok) {
        toast.success('Preferences updated successfully');
        onClose();
      } else {
        toast.error(`Failed to update preferences: ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`Error updating preferences: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  };

  const handleAddPreferenceClick = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  return (
    <Modal
      title="Edit Provider Preferences"
      open={isOpen}
      onCancel={onClose}
    >
      {isLoading ? (
        <div>Loading preferences...</div>
      ) : (
        <Form 
          onSubmit={handleSubmit}
          footer={{
            onCancel: onClose,
            submitText: "Update Preferences"
          }}
        >
          <div className={styles.addPreference}>
            <Dropdown
              menu={{
                items: availablePreferences.map(p => `${p.ppId} - ${p.text}`),
                onSelect: handleAddPreference,
              }}
            >
              <Button 
                variant="outline" 
                className={styles.addPreferenceButton}
                onClick={handleAddPreferenceClick}
                disabled={availablePreferences.length === 0}
              >
                <HiOutlinePlus />
                Add a Preference
              </Button>
            </Dropdown>
          </div>
          <div className={styles.preferenceList}>
            {providerPreferences.map(pp => {
              const pref = preferences.find(p => p.ppId === pp.ppId);
              return (
                <div key={pp.ppId} className={styles.preferenceItem}>
                  <div className={styles.preferenceInfo}>
                    <span className={styles.preferenceId}>{pp.ppId}</span>
                    <span className={styles.preferenceName}>{pref?.text}</span>
                  </div>
                  <div className={styles.preferenceActions}>
                    <Dropdown
                      menu={{
                        items: pref?.options.map(opt => opt.label) || [],
                        onSelect: (label) => {
                          const option = pref?.options.find(opt => opt.label === label);
                          if (option) {
                            handlePreferenceChange(pp.ppValId, option.value);
                          }
                        },
                      }}
                      size="small"
                    >
                      <div className={styles.preferenceValue}>
                        {pref?.options.find(opt => opt.value === pp.val)?.label || pp.val}
                        <span className={styles.iconWrapper}>
                          <IoIosArrowDown />
                        </span>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              );
            })}
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default EditPreferencesModal;

interface PreferenceChanges {
  add: { ppId: number; val: string }[];
  update: { ppValId: number; val: string }[];
}

interface Preference {
  ppId: number;
  text: string;
  answerFormat: string;
  options: { label: string; value: string }[];
  defaultValue: string;
}

interface ProviderPreference {
  ppValId: number;
  ppId: number;
  prid: number;
  set_on: string;
  val: string;
}

interface EditPreferencesModalProps {
  isOpen: boolean;
  onClose: () => void;
  providerId: number;
}
