import React from 'react';
import Modal from '../../../../../components/modal/Modal';
import styles from './SkuDetailsModal.module.scss';
import useSkuDetails from '../../../../../hooks/useSkuDetails';

interface SkuDetailsModalProps {
  open: boolean;
  onClose: () => void;
  skuId: number;
}

const SkuDetailsModal: React.FC<SkuDetailsModalProps> = ({ open, onClose, skuId }) => {
  const { skuDetails, isLoading, error } = useSkuDetails(skuId, open);

  const renderContent = () => {
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (!skuDetails) return <div>No SKU details found</div>;

    return (
      <table className={styles.skuDetailsTable}>
        <tbody>
          <tr>
            <th>SKU ID</th>
            <td>{skuDetails.skuId}</td>
          </tr>
          <tr>
            <th>SKU</th>
            <td>{skuDetails.sku}</td>
          </tr>
          <tr>
            <th>Notes</th>
            <td>{skuDetails.notes}</td>
          </tr>
          <tr>
            <th>Label</th>
            <td>{skuDetails.label}</td>
          </tr>
          <tr>
            <th>Active</th>
            <td>{skuDetails.isActive ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <th>Short Label</th>
            <td>{skuDetails.shortLabel}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <Modal
      title="Stock Keeping Units (SKU) Details"
      open={open}
      onCancel={onClose}
    >
      {renderContent()}
    </Modal>
  );
};

export default SkuDetailsModal;
