import React, { useState } from 'react';
import { AiOutlineQuestionCircle } from "react-icons/ai";
import styles from './Tooltip.module.scss';

type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

interface TooltipProps {
  text: string;
  position?: TooltipPosition;
}

export const Tooltip: React.FC<TooltipProps> = ({ text, position = 'right' }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div 
      className={styles.tooltipContainer}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <div className={styles.iconWrapper}>
        <AiOutlineQuestionCircle />
      </div>
      {isVisible && (
        <div className={`${styles.tooltipContent} ${styles[`tooltip--${position}`]}`}>
          {text}
        </div>
      )}
    </div>
  );
};
