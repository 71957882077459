import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../../components/inputs/Input/Input';
import { Button } from '../../../../components/buttons/Button/Button';
import { Dropdown } from '../../../../components/dropdown/Dropdown';
import { IoClose } from 'react-icons/io5';
import { IoIosArrowDown } from 'react-icons/io';
import ShippingDetails from '../sections/shipping/ShippingDetails';
import styles from './SummaryModal.module.scss';
import useProviderPreferenceKeys from '../../../../hooks/useProviderPreferenceKeys';
import useCountries from '../../../../hooks/useCountries';
import { Sku } from '../../../../hooks/useSkus';
import AdditionalDetails from '../sections/additional/AdditionalDetails';

interface SummaryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
  skus: Sku[];
}

interface InputRowProps {
  columns: 2 | 3;
  children: React.ReactNode;
}

const InputRow: React.FC<InputRowProps> = ({ columns, children }) => (
  <div className={`${styles.inputRow} ${styles[`columns${columns}`]}`}>
    {children}
  </div>
);

const SummaryModal: React.FC<SummaryModalProps> = ({ 
  isOpen, 
  onClose, 
  onSubmit,
  isSubmitting,
  skus 
}) => {
  const { control, setValue, watch } = useFormContext();
  const { preferenceKeys } = useProviderPreferenceKeys();
  const { countries } = useCountries();
  const preferences = watch('preferences');
  const watchCountry = watch('provider.country');
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const pricing = watch('pricing');
  
  const defaultPricing = pricing?.filter((item: any) => item.dashAdminDefault === true) || [];
  const additionalPricing = pricing?.filter((item: any) => item.dashAdminDefault === false) || [];

  useEffect(() => {
    if (countries && watchCountry) {
      const matchingCountry = countries.find(c => c.country === watchCountry);
      if (matchingCountry) {
        setSelectedCountry(matchingCountry.label);
      }
    }
  }, [watchCountry, countries]);

  const handleCountrySelect = (label: string) => {
    setSelectedCountry(label);
    const selectedCountryData = countries?.find(c => c.label === label);
    if (selectedCountryData) {
      setValue('provider.country', selectedCountryData.country, { shouldValidate: true });
    }
  };

  if (!isOpen) return null;

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    onSubmit();
  };

  const getPreferenceText = (ppId: number) => {
    const prefKey = preferenceKeys?.find(key => key.ppId === ppId);
    return prefKey?.text || 'Unknown Preference';
  };

  const getPreferenceValue = (ppId: number, val: string) => {
    const prefKey = preferenceKeys?.find(key => key.ppId === ppId);
    if (prefKey?.dataType === 0) {
      return val === "1" ? "Yes" : "No";
    }
    return val;
  };

  const getSkuLabel = (skuId: number) => {
    const sku = skus.find(s => s.skuId === skuId);
    return sku ? `${skuId} - ${sku.shortLabel}` : skuId.toString();
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2>Review Provider Details</h2>
          <button onClick={onClose} className={styles.closeButton}>
            <IoClose size={24} />
          </button>
        </div>

        <div className={styles.modalContent}>
          <div className={styles.section}>
            <h3>Site Details</h3>
            <div className={styles.fields}>
              <InputRow columns={3}>
                <Input
                  name="provider.label"
                  label="Site Label"
                  control={control}
                />
                <Input
                  name="provider.description"
                  label="Description"
                  control={control}
                />
                <div className={styles.dropdownWrapper}>
                  <label className={styles.label}>Country</label>
                  <Dropdown
                    menu={{
                      items: countries?.map(country => country.label).filter(c => c !== selectedCountry) || [],
                      onSelect: handleCountrySelect,
                    }}
                    size="small"
                  >
                    <div className={styles.dropdownValue}>
                      {selectedCountry || 'Select Country'}
                      <span className={styles.iconWrapper}>
                        <IoIosArrowDown />
                      </span>
                    </div>
                  </Dropdown>
                </div>
              </InputRow>
            </div>
          </div>

          <div className={styles.section}>
            <h3>Preferences</h3>
            {preferences?.length > 0 ? (
              <div className={styles.tableWrapper}>
                <table className={styles.preferencesTable}>
                  <thead>
                    <tr>
                      <th style={{ width: '15%' }}>ID</th>
                      <th style={{ width: '60%' }}>Description</th>
                      <th style={{ width: '25%' }}>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {preferences.map((pref: any) => (
                      <tr key={pref.ppId}>
                        <td>{pref.ppId}</td>
                        <td>{getPreferenceText(pref.ppId)}</td>
                        <td>{getPreferenceValue(pref.ppId, pref.val)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className={styles.noPreferences}>No preferences selected</p>
            )}
          </div>

          <div className={styles.section}>
            <ShippingDetails variant="summary" />
          </div>

          <div className={styles.section}>
            <h3>Pricing Details</h3>
            
            {/* Default Pricing Table */}
            <div className={styles.subsection}>
              <h4>Default Pricing</h4>
              {defaultPricing.length > 0 ? (
                <div className={styles.tableWrapper}>
                  <table className={styles.preferencesTable}>
                    <thead>
                      <tr>
                        <th style={{ width: '45%' }}>SKU</th>
                        <th style={{ width: '40%' }}>Description</th>
                        <th style={{ width: '15%' }}>{`Price (${defaultPricing[0].currency})`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {defaultPricing.map((item: any) => (
                        <tr key={item.sku_id}>
                          <td>{getSkuLabel(item.sku_id)}</td>
                          <td>{item.description}</td>
                          <td>{item.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className={styles.noPreferences}>No default pricing available</p>
              )}
            </div>

            {/* Additional Pricing Table */}
            <div className={styles.subsection}>
              <h4>Additional Pricing</h4>
              {additionalPricing.length > 0 ? (
                <div className={styles.tableWrapper}>
                  <table className={styles.preferencesTable}>
                    <thead>
                      <tr>
                        <th style={{ width: '45%' }}>SKU</th>
                        <th style={{ width: '40%' }}>Description</th>
                        <th style={{ width: '15%' }}>{`Price (${defaultPricing[0].currency})`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {additionalPricing.map((item: any) => (
                        <tr key={item.sku_id}>
                          <td>{getSkuLabel(item.sku_id)}</td>
                          <td>{item.description}</td>
                          <td>{item.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className={styles.noPreferences}>No additional pricing added</p>
              )}
            </div>
          </div>

          <div className={styles.section}>
            <h3>Additional Details</h3>
            <AdditionalDetails 
              variant="summary" 
            />
          </div>
        </div>

        <div className={styles.modalFooter}>
          <Button variant="outline" onClick={onClose}>
            Back to Edit
          </Button>
          <Button 
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Submit Provider
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SummaryModal;
