import React from 'react';
import { Button } from '../buttons/Button/Button';
import { FaChevronLeft, FaPlus } from "react-icons/fa";
import styles from './Header.module.scss';

interface HeaderProps {
  title: string;
  actionButton?: {
    label: string;
    onClick: () => void;
  };
}

const Header: React.FC<HeaderProps> = ({ title, actionButton }) => {
  return (
    <div className={styles.header}>
      <div className={styles.leftSection}>
        <Button
          variant="ghost"
          icon={<FaChevronLeft />}
          size="icon"
          onClick={() => window.history.back()}
        />
        <h1>{title}</h1>
      </div>
      {actionButton && (
        <Button
          variant="primary"
          icon={<FaPlus />}
          onClick={actionButton.onClick}
        >
          {actionButton.label}
        </Button>
      )}
    </div>
  );
};

export default Header;
