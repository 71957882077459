import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../../../components/inputs/Input/Input';
import { Dropdown } from '../../../../../components/dropdown/Dropdown';
import useCountries from '../../../../../hooks/useCountries';
import { IoIosArrowDown } from 'react-icons/io';
import Switch from '../../../../../components/switch/Switch';
import styles from './ShippingDetails.module.scss';

interface ShippingDetailsProps {
  variant?: 'default' | 'summary';
}

const ShippingDetails: React.FC<ShippingDetailsProps> = ({ variant = 'default' }) => {
  const { control, setValue, watch, clearErrors } = useFormContext();
  const { countries } = useCountries();
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const isEnabled = watch('shipping.enabled');
  const providerCountry = watch('provider.country');

  useEffect(() => {
    if (countries && providerCountry && isEnabled) {
      const matchingCountry = countries.find(c => c.country === providerCountry);
      if (matchingCountry) {
        setSelectedCountry(matchingCountry.label);
      }
    } else if (!isEnabled) {
      setSelectedCountry(null);
    }
  }, [providerCountry, countries, isEnabled]);

  useEffect(() => {
    if (!isEnabled) {
      clearErrors('shipping');
    }
  }, [isEnabled, clearErrors]);

  const handleCountrySelect = (label: string) => {
    setSelectedCountry(label);
    const selectedCountryData = countries?.find(c => c.label === label);
    if (selectedCountryData) {
      setValue('shipping.shippingCountry', selectedCountryData.country, { shouldValidate: true });
    }
  };

  const handleShippingToggle = (newChecked: boolean) => {
    setValue('shipping.enabled', newChecked, { shouldValidate: true });
    if (!newChecked) {
      ['shippingName', 'shippingEmail', 'shippingPhone', 'shippingAddress1',
       'shippingCity', 'shippingState', 'shippingZip', 'shippingCountry'].forEach(field => {
        setValue(`shipping.${field}`, '', { shouldValidate: false });
      });
    } else {
      setValue('shipping.shippingCountry', providerCountry, { shouldValidate: true });
    }
  };

  const getValidationRules = (field: string) => {
    if (!isEnabled) return {};
    
    const rules: { [key: string]: any } = {
      shippingName: { required: 'Shipping name is required' },
      shippingEmail: { 
        required: 'Email is required',
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "Invalid email address"
        }
      },
      shippingPhone: {
        required: 'Phone number is required',
        pattern: {
          value: /^\+?\d{10,15}$/,
          message: "Invalid phone number"
        }
      },
      shippingAddress1: { required: 'Address is required' },
      shippingCity: { required: 'City is required' },
      shippingZip: { required: 'Postal/Zip code is required' }
    };

    return rules[field] || {};
  };

  const containerClass = variant === 'summary' ? styles.summaryContainer : styles.container;
  const headerClass = variant === 'summary' ? styles.summaryHeader : styles.header;
  const contentClass = variant === 'summary' ? styles.summaryContent : styles.content;

  return (
    <div className={containerClass}>
      <div className={headerClass}>
        {variant === 'summary' ? (
          <h3>Shipping Details</h3>
        ) : (
          <h2>Shipping Details</h2>
        )}
        <Switch 
          checked={isEnabled}
          onChange={handleShippingToggle}
        />
      </div>
      
      {!isEnabled ? (
        <div className={styles.disabledMessage}>
          <div className={styles.messageCard}>
            <p>Enable shipping using the toggle above if you need to add shipping details for this provider.</p>
          </div>
        </div>
      ) : (
        <div className={contentClass}>
          {variant === 'summary' ? (
            <>
              <div className={styles.summaryRow}>
                <div className={styles.summaryHalf}>
                  <Input 
                    name="shipping.shippingName" 
                    label="Shipping Name"
                    placeholder='Recipient Name (e.g. "John Doe")'
                    control={control}
                    rules={getValidationRules('shippingName')} 
                    required={isEnabled}
                  />
                </div>
                <div className={styles.summaryHalf}>
                  <div className={styles.summaryInputGroup}>
                    <Input 
                      name="shipping.shippingEmail" 
                      label="Email"
                      placeholder='Email (e.g. "john.doe@example.com")'
                      type="email"
                      control={control}
                      rules={getValidationRules('shippingEmail')} 
                      required={isEnabled}
                    />
                    <Input 
                      name="shipping.shippingPhone" 
                      label="Phone Number"
                      placeholder='Phone Number (e.g. "+1234567890")'
                      type="tel"
                      control={control}
                      rules={getValidationRules('shippingPhone')} 
                      required={isEnabled}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.summaryRow}>
                <Input 
                  name="shipping.shippingAddress1" 
                  label="Address" 
                  placeholder='Address (e.g. "123 Main St")'
                  control={control}
                  rules={getValidationRules('shippingAddress1')} 
                  required={isEnabled}
                />
                <Input 
                  name="shipping.shippingCity" 
                  label="City" 
                  placeholder='City (e.g. "San Francisco")'
                  control={control}
                  rules={getValidationRules('shippingCity')} 
                  required={isEnabled}
                />
                <Input 
                  name="shipping.shippingState" 
                  label="State"
                  placeholder='State (e.g. "California")'
                  control={control}
                />
              </div>

              <div className={styles.summaryRow}>
                <Input 
                  name="shipping.shippingZip" 
                  label="Postal/Zip Code"
                  placeholder='Postal/Zip Code (e.g. "94103")'
                  control={control}
                  rules={getValidationRules('shippingZip')} 
                  required={isEnabled}
                />
                <div className={styles.dropdownWrapper}>
                  <label htmlFor="shipping.shippingCountry" className={styles.label}>
                    Country {isEnabled && '*'}
                  </label>
                  <Dropdown
                    menu={{
                      items: countries?.map(country => country.label) || [],
                      onSelect: handleCountrySelect,
                    }}
                    size="small"
                  >
                    <div className={styles.preferenceValue}>
                      {selectedCountry || 'Select Country'}
                      <span className={styles.iconWrapper}>
                        <IoIosArrowDown />
                      </span>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.inputGroup}>
                <Input 
                  name="shipping.shippingName" 
                  label="Shipping Name"
                  placeholder='Recipient Name (e.g. "John Doe")'
                  control={control}
                  rules={getValidationRules('shippingName')} 
                  required={isEnabled}
                />
              </div>
              <div className={styles.inputGroup}>
                <Input 
                  name="shipping.shippingEmail" 
                  label="Email"
                  placeholder='Email (e.g. "john.doe@example.com")'
                  type="email"
                  control={control}
                  rules={getValidationRules('shippingEmail')} 
                  required={isEnabled}
                />
                <Input 
                  name="shipping.shippingPhone" 
                  label="Phone Number"
                  placeholder='Phone Number (e.g. "+1234567890")'
                  type="tel"
                  control={control}
                  rules={getValidationRules('shippingPhone')} 
                  required={isEnabled}
                />
              </div>
              <Input 
                name="shipping.shippingAddress1" 
                label="Address" 
                placeholder='Address (e.g. "123 Main St")'
                control={control}
                rules={getValidationRules('shippingAddress1')} 
                required={isEnabled}
              />
              <div className={styles.inputGroup}>
                <Input 
                  name="shipping.shippingCity" 
                  label="City" 
                  placeholder='City (e.g. "San Francisco")'
                  control={control}
                  rules={getValidationRules('shippingCity')} 
                  required={isEnabled}
                />
                <Input 
                  name="shipping.shippingState" 
                  label="State"
                  placeholder='State (e.g. "California")'
                  control={control}
                />
              </div>
              <div className={styles.inputGroup}>
                <Input 
                  name="shipping.shippingZip" 
                  label="Postal/Zip Code"
                  placeholder='Postal/Zip Code (e.g. "94103")'
                  control={control}
                  rules={getValidationRules('shippingZip')} 
                  required={isEnabled}
                />
                <div className={styles.dropdownWrapper}>
                  <label htmlFor="shipping.shippingCountry" className={styles.label}>
                    Country {isEnabled && '*'}
                  </label>
                  <Dropdown
                    menu={{
                      items: countries?.map(country => country.label) || [],
                      onSelect: handleCountrySelect,
                    }}
                    size="small"
                  >
                    <div className={styles.preferenceValue}>
                      {selectedCountry || 'Select Country'}
                      <span className={styles.iconWrapper}>
                        <IoIosArrowDown />
                      </span>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ShippingDetails;