import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import useDefaultPricing from '../../../../../hooks/useDefaultPricing';
import { Dropdown } from '../../../../../components/dropdown/Dropdown';
import { Button } from '../../../../../components/buttons/Button/Button';
import { IoIosArrowDown } from 'react-icons/io';
import styles from './PricingDetails.module.scss';
import { authFetch } from "../../../../../utils/utils";
import { LoginContext } from "../../../../../contexts/LoginContext/LoginContext";
import { HiOutlinePlus } from "react-icons/hi2";
import { Sku } from "../../../../../hooks/useSkus";
import { Country } from '../../../../../hooks/useCountries';

interface PricingItem {
  pricingId: number;
  skuId: number;
  currency: string;
  price: number;
  description: string;
  taxAmount: number;
  dashAdminDefault: boolean;
}

interface TableDataItem {
  pricingId: number;
  skuId: number;
  sku: string;
  description: string;
  price: number;
  currency: string;
}

interface EditedPricingItem {
  description: string;
  price: number;
}

interface EditedPricing {
  [key: number]: EditedPricingItem;
}

interface AdditionalTableDataItem {
  skuId: number;
  sku: string;
  description: string;
  price: number;
  currency: string;
}

interface Props {
  skus: Sku[];
  countries: Country[];
}

const PricingDetails: React.FC<Props> = ({ skus, countries }) => {
  const { state: { loginToken } } = useContext(LoginContext);
  const { watch, setValue, getValues } = useFormContext();
  const providerCurrency = watch('provider.currency');
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [defaultPricing, setDefaultPricing] = useState<PricingItem[] | null>(null);
  const [defaultTableData, setDefaultTableData] = useState<TableDataItem[] | null>(null);
  const [editedPricing, setEditedPricing] = useState<EditedPricing>({});
  const [additionalTableData, setAdditionalTableData] = useState<AdditionalTableDataItem[]>([]);

  // Track initial load and currency changes
  useEffect(() => {
    if (providerCurrency) {
      setSelectedCurrency(providerCurrency);
      // Only fetch if:
      // 1. Form data is empty OR
      // 2. Currency was explicitly updated via dropdown
      const currentPricing = getValues('pricing');
      if (!currentPricing?.length || selectedCurrency !== providerCurrency) {
        fetchLatestPricing(providerCurrency, selectedCurrency !== providerCurrency);
      }
    }
  }, [providerCurrency]);

  // Track pricing data updates
  useEffect(() => {
    if (defaultPricing && skus) {
      updateDefaultTableData(defaultPricing);
    }
  }, [defaultPricing, skus, editedPricing]);

  const updateDefaultTableData = (pricing: PricingItem[]) => {
    if (!skus) {
      console.warn('SKUs not available yet');
      return;
    }

    const tableData = pricing.map((item) => {
      const matchingSku = skus.find((sku: any) => sku.skuId === item.skuId);
      const skuIdAndName = `${item.skuId} - ${matchingSku?.sku || 'N/A'}`;
      
      // Check if we have edited values for this SKU
      const editedValues = editedPricing[item.skuId];
      
      return {
        pricingId: item.pricingId,
        skuId: item.skuId,
        sku: skuIdAndName,
        // Use edited values if they exist, otherwise use original values
        description: editedValues?.description ?? item.description,
        price: editedValues?.price ?? item.price,
        currency: item.currency
      };
    });
    
    setDefaultTableData(tableData);
  };

  const handlePricingChange = (pricingId: number, skuId: number, field: 'description' | 'price', value: string) => {
    const newEditedPricing: EditedPricing = {
      ...editedPricing,
      [skuId]: {
        ...editedPricing[skuId],
        [field]: field === 'price' ? parseFloat(value) || 0 : value
      }
    };
    setEditedPricing(newEditedPricing);
    
    // Update form data while preserving additional pricing
    if (defaultTableData) {
      const currentPricing = getValues('pricing');
      const additionalPricing = currentPricing.filter((item: any) => item.dashAdminDefault === false);
      
      const formattedDefaultPricing = defaultTableData.map((item) => ({
        sku_id: item.skuId,
        currency: item.currency,
        price: newEditedPricing[item.skuId]?.price ?? item.price,
        description: newEditedPricing[item.skuId]?.description ?? item.description,
        tax_amount: 0,
        dashAdminDefault: true
      }));

      setValue('pricing', [...formattedDefaultPricing, ...additionalPricing]);
    }
  };

  // Initialize editedPricing from existing form data when component mounts
  useEffect(() => {
    const currentPricing = getValues('pricing');
    
    if (currentPricing?.length > 0) {
      const existingEdits = currentPricing
        .filter((item: any) => item.dashAdminDefault === true)
        .reduce((acc: EditedPricing, item: any) => ({
          ...acc,
          [item.sku_id]: {
            description: item.description,
            price: item.price
          }
        }), {});
      setEditedPricing(existingEdits);
    }
  }, [getValues]);

  // Reset editedPricing only when currency changes
  useEffect(() => {
    if (providerCurrency && selectedCurrency && providerCurrency !== selectedCurrency) {
      setEditedPricing({});  // Reset edited values only when currency actually changes
    }
  }, [providerCurrency]);

  const uniqueCurrencies = Array.from(new Set(countries
    ?.map(c => c.currency)
    .filter((currency): currency is string => !!currency)
  ))

  const handleCurrencySelect = (currency: string) => {
    setSelectedCurrency(currency);
  };

  const handleUpdateCurrency = async () => {
    if (selectedCurrency) {
      // Reset edited values before updating currency
      setEditedPricing({});
      
      // Reset additional pricing table
      setAdditionalTableData([]);
      
      setValue('provider.currency', selectedCurrency, { 
        shouldValidate: true,
        shouldDirty: true 
      });
      
      // Pass true to indicate this is a currency update
      await fetchLatestPricing(selectedCurrency, true);
    }
  };

  const fetchLatestPricing = async (currency: string, isCurrencyUpdate: boolean) => {
    setIsLoading(true);
    if (!loginToken) {
      console.error("No login token available");
      return;
    }
    try {
      const res = await authFetch(loginToken, `/api/get-default-pricing/${currency}`);
      const { pricing } = await res.json();
      setDefaultPricing(pricing);
      
      // Format pricing data while preserving descriptions and values
      const formattedDefaultPricing = pricing.map((item: PricingItem) => ({
        sku_id: item.skuId,
        currency: currency,
        price: item.price,  // This should use the AUD price from API
        description: item.description,  // This should use the AUD description
        tax_amount: 0,
        dashAdminDefault: true
      }));

      if (isCurrencyUpdate) {
        // Replace all pricing data when currency changes
        setValue('pricing', formattedDefaultPricing);
      } else {
        // Keep existing form data if not a currency update
        const currentPricing = getValues('pricing');
        if (!currentPricing?.length) {
          setValue('pricing', formattedDefaultPricing);
        }
      }
    } catch (err) {
      setFetchError(err instanceof Error ? err.message : 'Failed to fetch pricing');
    } finally {
      setIsLoading(false);
    }
  };

  const additionalSkus = skus
    ?.filter((sku: Sku) => {
      const inDefaultTable = defaultTableData?.some(
        (item) => item.skuId === sku.skuId
      );
      
      const inAdditionalTable = additionalTableData.some(
        (item) => item.skuId === sku.skuId
      );
      
      return !inDefaultTable && !inAdditionalTable;
    })
    .sort((a: Sku, b: Sku) => a.skuId - b.skuId);

  const handleSkuSelect = (selectedSku: string) => {
    const [skuId] = selectedSku.split(' - ');
    const matchingSku = skus?.find((sku: any) => sku.skuId === parseInt(skuId));
    
    if (matchingSku) {
      const newRow: AdditionalTableDataItem = {
        skuId: matchingSku.skuId,
        sku: `${matchingSku.skuId} - ${matchingSku.sku}`,
        description: '',
        price: 0,
        currency: providerCurrency || '',
      };
      
      const updatedTableData = [...additionalTableData, newRow];
      setAdditionalTableData(updatedTableData);
      
      // Update form data
      const formattedPricing = [
        ...getValues('pricing'),
        {
          sku_id: matchingSku.skuId,
          currency: providerCurrency,
          price: 0,
          description: '',
          tax_amount: 0,
          dashAdminDefault: false
        }
      ];
      setValue('pricing', formattedPricing);
    }
  };

  const handleAddSkuClick = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  // Initialize additionalTableData from form data when component mounts
  useEffect(() => {
    const currentPricing = getValues('pricing');
    if (currentPricing?.length > 0) {
      // Filter for non-default pricing items
      const additionalPricing = currentPricing.filter((item: any) => 
        item.dashAdminDefault === false
      );
      
      if (additionalPricing.length > 0 && skus) {
        const tableData = additionalPricing.map((item: any) => {
          const matchingSku = skus.find((sku: any) => sku.skuId === item.sku_id);
          return {
            skuId: item.sku_id,
            sku: `${item.sku_id} - ${matchingSku?.sku || 'N/A'}`,
            description: item.description,
            price: item.price,
            currency: item.currency
          };
        });
        setAdditionalTableData(tableData);

        // Re-add additional pricing to form data
        const currentFormPricing = getValues('pricing');
        const defaultPricing = currentFormPricing.filter((item: any) => item.dashAdminDefault === true);
        const updatedPricing = [
          ...defaultPricing,
          ...additionalPricing.map((item: any) => ({
            ...item,
            dashAdminDefault: false
          }))
        ];
        setValue('pricing', updatedPricing);
      }
    }
  }, [skus]);

  const handleAdditionalPricingChange = (skuId: number, field: 'description' | 'price', value: string) => {
    // Update additional table data
    const updatedTableData = additionalTableData.map(item => {
      if (item.skuId === skuId) {
        return {
          ...item,
          [field]: field === 'price' ? parseFloat(value) || 0 : value
        };
      }
      return item;
    });
    setAdditionalTableData(updatedTableData);
    
    // Update form data while preserving edited default pricing
    const currentPricing = getValues('pricing');
    const defaultPricing = currentPricing
      .filter((item: any) => item.dashAdminDefault === true)
      .map((item: any) => ({
        ...item,
        // Preserve edited values from editedPricing state
        price: editedPricing[item.sku_id]?.price ?? item.price,
        description: editedPricing[item.sku_id]?.description ?? item.description
      }));
    
    const updatedAdditionalPricing = updatedTableData.map(item => ({
      sku_id: item.skuId,
      currency: providerCurrency,
      price: item.price,
      description: item.description,
      tax_amount: 0,
      dashAdminDefault: false
    }));

    setValue('pricing', [...defaultPricing, ...updatedAdditionalPricing]);
  };

  // Add this effect to maintain edited values
  useEffect(() => {
    const currentPricing = getValues('pricing');
    if (currentPricing?.length) {
      const existingEdits = currentPricing.reduce((acc: EditedPricing, item: any) => ({
        ...acc,
        [item.sku_id]: {
          description: item.description,
          price: item.price
        }
      }), {});
      setEditedPricing(existingEdits);
    }
  }, []);

  return (
    <div className={styles.container}>
      <h2>Pricing Details</h2>
      
      <div className={styles.currencyRow}>
        <div className={styles.dropdownWrapper}>
          <label className={styles.label}>Currency</label>
          <Dropdown
            menu={{
              items: uniqueCurrencies.filter(c => c !== selectedCurrency) || [],
              onSelect: handleCurrencySelect,
            }}
            size="small"
          >
            <div className={styles.dropdownValue}>
              {selectedCurrency || 'Select Currency'}
              <span className={styles.iconWrapper}>
                <IoIosArrowDown />
              </span>
            </div>
          </Dropdown>
        </div>
        <Button
          variant="primary"
          onClick={handleUpdateCurrency}
          disabled={!selectedCurrency || isLoading || selectedCurrency === providerCurrency}
          type="button"
        >
          {isLoading ? 'Loading...' : 'Update'}
        </Button>
      </div>

      <div className={styles.divider} />
      
      <div className={styles.sectionTitle}>
        Default Pricing
      </div>
      {isLoading ? (
        <p>Loading pricing data...</p>
      ) : (
        <>
          {defaultTableData && (
            <div className={styles.tableWrapper}>
              <table className={styles.pricingTable}>
                <thead>
                  <tr>
                    <th style={{ width: '40%' }}>SKU</th>
                    <th style={{ width: '50%' }}>Description</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {defaultTableData.map((item: any) => (
                    <tr key={item.pricingId}>
                      <td>{item.sku}</td>
                      <td>
                        <input
                          type="text"
                          className={styles.input}
                          value={editedPricing[item.skuId]?.description ?? item.description}
                          onChange={(e) => handlePricingChange(item.pricingId, item.skuId, 'description', e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className={styles.input}
                          value={editedPricing[item.skuId]?.price ?? item.price}
                          onChange={(e) => handlePricingChange(item.pricingId, item.skuId, 'price', e.target.value)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {fetchError && <p className={styles.error}>Error: {fetchError}</p>}
        </>
      )}

      <div className={styles.divider} />

      <div className={styles.sectionTitle}>
        Additional Pricing
      </div>
      <div className={styles.addSku}>
        <Dropdown
          menu={{
            items: additionalSkus?.map(sku => `${sku.skuId} - ${sku.shortLabel}`) || [],
            onSelect: handleSkuSelect,
          }}
        >
          <Button 
            variant="outline" 
            className={styles.addSkuButton}
            onClick={handleAddSkuClick}
          >
            <HiOutlinePlus />
            Select SKU
          </Button>
        </Dropdown>
      </div>

      {additionalTableData.length > 0 && (
        <div className={styles.tableWrapper}>
          <table className={styles.pricingTable}>
            <thead>
              <tr>
                <th style={{ width: '40%' }}>SKU</th>
                <th style={{ width: '50%' }}>Description</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {additionalTableData.map((item) => (
                <tr key={item.skuId}>
                  <td>{item.sku}</td>
                  <td>
                    <input
                      type="text"
                      className={styles.input}
                      value={item.description}
                      onChange={(e) => handleAdditionalPricingChange(item.skuId, 'description', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className={styles.input}
                      value={item.price}
                      onChange={(e) => handleAdditionalPricingChange(item.skuId, 'price', e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PricingDetails;