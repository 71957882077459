 /*
 * This is the router for the dashboard pages that appears after log in
 * (staff home, digest/challenges, digest/day-to-day etc.)
 *
 * All the routes are wrapped with an DashErrorBoundary
 * Thus if a UI crash happens in any of the routes, dashboard will
 *    - keep the path intact on browser's address bar
 *    - display <DashError/> instead of the relevant component ("Something Went Wrong")
 */

import React, { useContext } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { DateRangeContextProvider } from "../contexts/DateRangeContext/DateRangeContext";
import DashErrorBoundary from "../components/errors/DashErrorBoundary";
import Challenges from "../pages/digest/challenges/Challenges";
import DayToDay from "../pages/digest/day-to-day/DayToDay";
import Patient from "../pages/digest/patient/Patient";
import DashAdmin from "../pages/dash-admin/DashAdmin";
import Invite from "../pages/dash-admin/invite/Invite";
import PatientPasswordReset from "../pages/dash-admin/patient-password-reset/PatientPasswordReset";
import Contact from "../pages/contact/Contact";
import { LoginContext } from "../contexts/LoginContext/LoginContext";
import SiteOverview from "../pages/site-overview/SiteOverview";
import { PreLoginRoutes } from "./PreLoginRoutes";
import PrivacyPolicyPage from "../pages/privacy-policy/PrivacyPolicyPage";
import DashRoute, { NOT_FOUND_ROUTE } from "./DashRoute";
import Register from "../pages/register/Register";
import { LoggedInForbidden } from "./LoggedInForbidden";
import OrderHistory from "../pages/bulk-ordering/order-history/OrderHistory";
import DownloadInvoice from "../pages/invoice/DownloadInvoice";
import OrderingPage from "../pages/bulk-ordering/Ordering";
import AddCardPage from "../pages/bulk-ordering/add-card/AddCard";
import SetupComplete from "../pages/bulk-ordering/add-card/SetupComplete.tsx";
import SalesOverview from "../pages/sales/overview/SalesOverview";
import SalesDetailedOverview from "../pages/sales/detailed-overview/SalesDetailedOverview";
import AddProvider from "../pages/dash-admin/add-provider/AddProvider";
import AddProvider2 from "../pages/dash-admin/create-provider/AddProvider";
import MagicLinkLogin from "../pages/login/MagicLinkLogin";
import RequestOrderArrears from "../pages/dash-admin/order-requests/request-order/RequestOrderArrears";
import ReviewOrder from "../pages/dash-admin/order-requests/review-order/ReviewOrder";
import RequestOrderPrepaid from "../pages/dash-admin/order-requests/request-order/RequestOrderPrepaid";
import RequestOrder from "../pages/dash-admin/order-requests/request-order/RequestOrder";
import DownloadArrearsInvoice from "../pages/arrears-invoice/DownloadArrearsInvoice";
import ProvidersTable from "../pages/dash-admin/providers/ProvidersTable";
import ShippingTable from "../pages/dash-admin/providers/shipping/ShippingTable";
import PricingTable from "../pages/dash-admin/providers/pricing/PricingTable";


export const LoginRoutes = {
  BASE: new DashRoute("/",SiteOverview),
  MAGIC_LINK: new DashRoute("/login/magic-link/:token",MagicLinkLogin),
  HOME: new DashRoute("/home",null,<Redirect to="/" />),
  CHALLENGES: new DashRoute("/digest/challenges",Challenges),
  DAY_TO_DAY: new DashRoute("/digest/day-to-day",DayToDay),
  PATIENT: new DashRoute("/digest/patient",Patient),
  PRIVACY_POLICY: new DashRoute("/privacy-policy/",PrivacyPolicyPage),
  // SITE_OVERVIEW: new DashRoute("/site-overview",null,<Redirect to="/" />),
  REGISTER: new DashRoute("/register/:patientCode",Register),
  DASH_ADMIN: new DashRoute("/dash-admin/",DashAdmin),
  INVITE_STAFF: new DashRoute("/dash-admin/invite/",Invite),
  ADD_PROVIDER: new DashRoute("/dash-admin/add-provider",AddProvider),
  ADD_PROVIDER2: new DashRoute("/dash-admin/add-provider2",AddProvider2),

  ORDER_REQUEST: new DashRoute("/dash-admin/order-requests/request",RequestOrder),
  ORDER_REQUEST_ARREARS: new DashRoute("/dash-admin/order-requests/arrears",RequestOrderArrears),
  ORDER_REQUEST_PREPAID: new DashRoute("/dash-admin/order-requests/request/prepaid",RequestOrderPrepaid),
  ORDER_REQUEST_REVIEW: new DashRoute("/dash-admin/order-requests/review/:token",ReviewOrder),
  PROVIDERS_TABLE: new DashRoute("/dash-admin/providers", ProvidersTable),
  SHIPPING_ADDRESSES: new DashRoute("/dash-admin/providers/:providerId/shipping", ShippingTable),
  PRICING_TABLE: new DashRoute("/dash-admin/providers/:providerId/pricing", PricingTable),

  PATIENT_PASSWORD_RESET: new DashRoute("/dash-admin/patient-password-reset/",PatientPasswordReset),
  CONTACT: new DashRoute("/contact",Contact),
  INVOICE: new DashRoute("/invoice/:orid",DownloadInvoice),
  ORDERS: new DashRoute("/orders",OrderingPage),
  ORDER_HISTORY: new DashRoute("/orders/history",OrderHistory),
  ADD_CARD: new DashRoute("/orders/add-card",AddCardPage),
  ADD_CARD_SETUP_COMPLETE: new DashRoute("/orders/add-card/setup-complete",SetupComplete),
  INVOICE: new DashRoute("/invoice/:invoiceToken",DownloadInvoice),
  SALES: new DashRoute("/sales/overview",SalesOverview),
  SALES_DETAILED: new DashRoute("/sales/detailed-overview",SalesDetailedOverview),
  ARREARS_INVOICE: new DashRoute("/arrears-invoice/:token",DownloadArrearsInvoice),
}

export const PRE_AND_POST_LOGIN_ROUTES = [LoginRoutes.REGISTER,LoginRoutes.INVOICE];

const FORBIDDEN_PATHS = Object.values(PreLoginRoutes)
  .map(({path}) => path)
  .filter(path => !PRE_AND_POST_LOGIN_ROUTES.includes(path));

const DashRouter = () => {
  const { state: loginState } = useContext(LoginContext);
  const { staff, provider, patients } = loginState;

  // Do not attempt to render route before loginContext sets necessary info
  // Empty patients arrays are valid (In JS, empty arrays/objects are truthy and pass the following check)
  if (!staff || !provider || !patients) return null;

  return (
    <BrowserRouter>
      <DashErrorBoundary loginInfo={{ ...loginState }}>
        <DateRangeContextProvider>
          <Switch>
            {Object.values(LoginRoutes).map((route,i) => <Route exact key={i} {...route} />)}
            {/* Route used if user attempts to access a page that required them to be logged out */}
            <Route exact path={FORBIDDEN_PATHS} component={LoggedInForbidden} />
            <Route exact {...NOT_FOUND_ROUTE} />
          </Switch>
        </DateRangeContextProvider>
      </DashErrorBoundary>
    </BrowserRouter>
  );
};

export default DashRouter;
